var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.products)?_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.products,"item-key":"amz","search":_vm.search},on:{"click:row":_vm.openItem},scopedSlots:_vm._u([{key:"item.asin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.asin)+" ")]}},{key:"item.ProductPrice.perc_desc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.floor(item.ProductPrice.perc_desc))+" ")]}},{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"height":"50px","src":item.thumbnail}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openUrl(item.url)}}},[_c('v-icon',[_vm._v("fas fa-share")])],1)]}}],null,false,3973481080)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.selectedProd)?_c('v-card',[_c('v-card-title',{staticClass:"headline",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.selectedProd.title)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"readonly":"","id":"selectedProdUrl","rows":"3"},on:{"click":function($event){return _vm.copyTestingCode('selectedProdUrl')}},model:{value:(_vm.selectedProd.url),callback:function ($$v) {_vm.$set(_vm.selectedProd, "url", $$v)},expression:"selectedProd.url"}}),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.openUrl(_vm.selectedProd.url)}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("fas fa-share")]),_vm._v("Acessar URL")],1)],1),_c('v-col',{staticStyle:{"display":"none"},attrs:{"cols":"6"}},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.selectedProd.thumbnail,"width":"100%"},on:{"click":function($event){return _vm.copyTestingCode('selectedProdUrl')}}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Preço Atual: "+_vm._s(_vm.selectedProd.ProductPrice.price))]),(_vm.selectedProdLowestPrice)?_c('h2',[_vm._v(" Menor preço no período: R$: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.selectedProdLowestPrice.price))]),_vm._v(" / "+_vm._s(_vm._f("moment")(_vm.selectedProdLowestPrice.updated_at,'D/MM/YYYY HH:mm'))+" ")]):_vm._e(),(
                _vm.selectedProdLowestPrice &&
                  _vm.selectedProdLowestPrice.price ==
                    _vm.selectedProd.ProductPrice.price
              )?_c('h2',{staticStyle:{"color":"#ff00fb","text-align":"center"}},[_c('v-icon',{staticStyle:{"color":"red"}},[_vm._v("fas fa-fire")]),_c('i',[_vm._v("Este produto está com o menor preço!")]),_c('v-icon',{staticStyle:{"color":"red"}},[_vm._v("fas fa-fire")])],1):_vm._e()]),(
              _vm.selectedProd &&
                _vm.selectedProd.asin &&
                _vm.selectedProd.asin[0] == 1 &&
                _vm.$lConfig.User.keepa_key
            )?_c('v-col',{attrs:{"cols":"12"}},[(_vm.keepaImage)?_c('img',{attrs:{"src":_vm.keepaImage,"width":"100%"}}):_vm._e(),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.loadKeepaGraph(_vm.$lConfig.User.keepa_key, _vm.selectedProd.asin)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sync")]),_vm._v(" Carregar gráfico Keepa")],1)],1):_vm._e(),(_vm.prices && !_vm.loadingPrices)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"color":"grey"}},[_c('v-sparkline',{attrs:{"value":_vm.pricesValues,"color":"#0b008f","height":"50","line-width":"1.5","padding":"15","label-size":"6.5","stroke-linecap":"round","smooth":""},scopedSlots:_vm._u([{key:"label",fn:function(item){return [_vm._v(" R$"+_vm._s(item.value)+" ")]}}],null,false,3933385321)})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.priceHeaders,"items":_vm.prices,"items-per-page":5},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.updated_at,'D/MM/YYYY HH:mm')))])]}},{key:"item.perc_desc",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(Math.floor(item.perc_desc))+" ")]}}],null,false,3722319406)})],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer')],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }