<template>
  <div>
    <v-data-table
      v-if="products"
      style="cursor: pointer;"
      :headers="headers"
      :items="products"
      item-key="amz"
      class="elevation-1"
      :search="search"
      @click:row="openItem"
    >
      <template v-slot:item.asin="{ item }">
        {{ item.asin }}
      </template>
      <template v-slot:item.ProductPrice.perc_desc="{ item }">
        {{ Math.floor(item.ProductPrice.perc_desc) }}
      </template>
      <template v-slot:item.thumbnail="{ item }">
        <img height="50px" :src="item.thumbnail" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="success" icon @click.stop="openUrl(item.url)"
          ><v-icon>fas fa-share</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="800">
      <v-card v-if="selectedProd">
        <v-card-title class="headline" primary-title>
          {{ selectedProd.title }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-textarea
                readonly
                v-model="selectedProd.url"
                id="selectedProdUrl"
                rows="3"
                @click="copyTestingCode('selectedProdUrl')"
              ></v-textarea>
              <v-btn color="success" @click="openUrl(selectedProd.url)"
                ><v-icon class="mr-3">fas fa-share</v-icon>Acessar URL</v-btn
              >
            </v-col>
            <v-col cols="6" style="display: none">
              <img
                style="cursor: pointer;"
                :src="selectedProd.thumbnail"
                width="100%"
                @click="copyTestingCode('selectedProdUrl')"
              />
            </v-col>
            <v-col cols="12">
              <h2>Preço Atual: {{ selectedProd.ProductPrice.price }}</h2>
              <h2 v-if="selectedProdLowestPrice">
                Menor preço no período: R$:
                <span style="color:red;">{{
                  selectedProdLowestPrice.price
                }}</span>
                /
                {{
                  selectedProdLowestPrice.updated_at | moment('D/MM/YYYY HH:mm')
                }}
              </h2>
              <h2
                v-if="
                  selectedProdLowestPrice &&
                    selectedProdLowestPrice.price ==
                      selectedProd.ProductPrice.price
                "
                style="color:#ff00fb; text-align: center;"
              >
                <v-icon style="color:red;">fas fa-fire</v-icon>
                <i>Este produto está com o menor preço!</i>
                <v-icon style="color:red;">fas fa-fire</v-icon>
              </h2>
            </v-col>
            <v-col
              cols="12"
              v-if="
                selectedProd &&
                  selectedProd.asin &&
                  selectedProd.asin[0] == 1 &&
                  $lConfig.User.keepa_key
              "
            >
              <img v-if="keepaImage" :src="keepaImage" width="100%" />
              <v-btn
                color="error"
                @click="
                  loadKeepaGraph($lConfig.User.keepa_key, selectedProd.asin)
                "
                ><v-icon class="mr-2">fas fa-sync</v-icon> Carregar gráfico
                Keepa</v-btn
              >
            </v-col>
            <v-col cols="12" v-if="prices && !loadingPrices">
              <v-sheet color="grey">
                <v-sparkline
                  :value="pricesValues"
                  color="#0b008f"
                  height="50"
                  line-width="1.5"
                  padding="15"
                  label-size="6.5"
                  stroke-linecap="round"
                  smooth
                >
                  <template v-slot:label="item"> R${{ item.value }} </template>
                </v-sparkline>
              </v-sheet>
              <v-data-table
                :headers="priceHeaders"
                :items="prices"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:item.updated_at="{ item }">
                  <span>{{ item.updated_at | moment('D/MM/YYYY HH:mm') }}</span>
                </template>
                <template v-slot:item.perc_desc="{ item }">
                  {{ Math.floor(item.perc_desc) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'PriceNews',
  components: {},
  props: {
    products: Array,
    search: String
  },
  data() {
    return {
      selectedProd: null,
      selectedProdLowestPrice: null,
      keepaImage: null,
      dialog: false,
      loadingPrices: false,
      headers: [
        { text: 'ID', value: 'asin' },
        { text: '', value: 'thumbnail' },
        { text: 'Nome', value: 'title' },
        { text: 'Preço', value: 'ProductPrice.price' },
        { text: 'Preço anterior', value: 'ProductPrice.last_price' },
        { text: '%', value: 'ProductPrice.perc_desc' },
        { text: 'Ações', value: 'actions' }
      ],
      priceHeaders: [
        { text: 'Data de atualização', value: 'updated_at' },
        { text: 'Preço', value: 'price' },
        { text: 'Des %', value: 'perc_desc' }
      ],
      prices: []
    }
  },
  mounted() {},
  created() {},
  methods: {
    copyTestingCode(_elementID) {
      var vm = this
      let testingCodeToCopy = document.querySelector(`#${_elementID}`)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        if (document.execCommand('copy')) {
          vm.$snotify.success(`Texto copiado para a área de transferência`)
        } else {
          throw new Error(
            'Não foi possível copiar o texto para área de transferência. Copie manualmente'
          )
        }
      } catch (_err) {
        vm.$snotify.error(`Oops... ${_err.message}`)
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    openUrl(_url) {
      window.open(_url, '_blank')
    },
    openItem(_i) {
      var vm = this
      vm.selectedProd = _i

      vm.keepaImage = window.localStorage.getItem(
        vm.selectedProd.asin.replace('1-', '')
      )

      vm.loadingPrices = true
      vm.dialog = true
      vm.$API
        .Request('post', `prices/list`, { product_id: vm.selectedProd.id })
        .then(result => {
          vm.loadingPrices = false
          if (result && result.data) {
            vm.prices = result.data
            if (vm.prices && vm.prices.length > 0) {
              vm.selectedProdLowestPrice = vm.prices.reduce((prev, curr) => {
                let number =
                  prev.price && prev.price > 0
                    ? prev.price < curr.price
                      ? prev
                      : curr
                    : curr
                let number2 =
                  curr.price && curr.price > 0
                    ? prev.price < curr.price
                      ? prev
                      : curr
                    : prev

                let result = parseInt(number.price) > 0 ? number : number2
                return result
              })
            } else {
              vm.selectedProdLowestPrice = null
            }
          }
        })
        .catch(error => {
          vm.loadingPrices = false
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    loadKeepaGraph(key, asin) {
      var vm = this
      vm.loadingPrices = true
      console.log(asin)
      asin = asin.replace('1-', '')
      let domain = 12
      let url = `https://api.keepa.com/graphimage?key=${key}&domain=${domain}&asin=${asin}`
      vm.axios({
        method: 'GET',
        url: url,
        crossDomain: true,
        headers: {},
        responseType: 'blob'
      })
        .then(async response => {
          let freader = new FileReader()
          freader.onloadend = function() {
            let base64data = freader.result
            localStorage.setItem(asin, base64data)
            vm.keepaImage = base64data
            vm.loadingPrices = false
          }
          freader.readAsDataURL(response.data)
        })
        .catch(function(error) {
          console.error(error)
          vm.$snotify.error(`Oops... ${error.message}`)
          vm.loadingPrices = false
        })
    }
  },
  computed: {
    pricesValues: function() {
      var vm = this
      let _values = []
      if (vm.prices && vm.prices.length > 0) {
        let arr = vm.prices.length > 10 ? vm.prices.slice(-10) : vm.prices
        arr.forEach(price => {
          _values.push(parseFloat(price.price))
        })
      }
      return _values
    }
  }
}
</script>
